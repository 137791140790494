import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

const reactPlugin = new ReactPlugin();

const appInsights = new ApplicationInsights({
    config: {
        connectionString: process.env.REACT_APP_INSIGHTS_CONNECTION_STRING,
        extensions: [reactPlugin],
        enableAjaxPerfTracking: true,
        enableAutoRouteTracking: true,
        autoTrackPageVisitTime: true
    }
});

appInsights.loadAppInsights();
appInsights.trackPageView();

export { reactPlugin, appInsights };