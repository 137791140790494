import jwt_decode from 'jwt-decode';
import { useEffect, useState } from 'react';
import { AccessTokenMsal } from './../interfaces';
import { getAccessToken } from '../utils/access-token/get-access-token';
import { appInsights } from '../AppInsights';

export interface UserProfile {
  name: string;
  email: string;
  shellId: string;
  isAuthenticated: boolean;
  roles: string[];
  shortUsername: string;
}

const initialUserProfile: UserProfile = {
  name: '',
  email: '',
  shellId: '',
  roles: [],
  isAuthenticated: false,
  shortUsername: '',
};

export const useUserProfile = (): UserProfile => {
    const [userProfile, setUserProfile] = useState<UserProfile>(initialUserProfile);
    useEffect(() => {
        if (!userProfile.isAuthenticated) {
            getAccessToken().then((accessToken) => {
                if (accessToken) {
                    const { email, name, realm_access, preferred_username: shellId }: AccessTokenMsal = jwt_decode(accessToken);

                    // Set the authenticated user context for App Insights
                    appInsights.setAuthenticatedUserContext(shellId, "AllUsers", true);
                    
                    setUserProfile({
                        name,
                        email,
                        shellId,
                        isAuthenticated: true,
                        roles: realm_access.roles,
                        shortUsername: email.split('@')[0],
                    });
                }
            });
        }
    }, [userProfile.isAuthenticated]);

    return userProfile;
};
